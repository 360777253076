<template>
  <div>

    <CRow>
      <CCol lg="12">
        <CCard>
        <CCardHeader>
          <slot name="header">
            <!-- <CIcon name="cil-grid"/> --><h4 class="float-left">Data Iklan</h4> 
            <a href="#/iklan/add" class="float-right btn btn-success form-control-sm">Tambah Iklan</a>

          </slot>
        </CCardHeader>
        <CCardBody>
      
        <table ref="table" class="table" id="datatable" :options="{ deleteIklan }">
          <thead>
            <tr>
              <th style="width:30px">#</th>
              <th>Nama </th>
              <th style="width:100px">Ukuran</th>
              <th style="width:150px">File</th>
              <th style="width:150px">Link</th>
              <th style="width:70px">Status</th>
              <th style="width:140px">Aksi</th>
            </tr>
          </thead>
          <tbody>

          </tbody>
        </table>  

    </CCardBody>
  </CCard>  
  
  </CCol>
    </CRow>
    <CModal
      :show.sync="deleteModal"
      :no-close-on-backdrop="true"
      :centered="true"
      title="Hapus Data"
      color="danger"
    >
      Anda Yakin Ingin Menghapus Data Ini?
      <template #header>
        <h6 class="modal-title">Hapus Data</h6>
        <CButtonClose @click="darkModal = false" class="text-white"/>
      </template>
      <template #footer>
        <CButton @click="deleteModal = false" color="light">Batal</CButton>
        <CButton @click="deleteIklan()" color="danger">Hapus</CButton>
      </template>
    </CModal>
  </div>
</template>
 
<script>
import 'jquery/dist/jquery.min.js';
// import 'bootstrap/dist/css/bootstrap.min.css';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import axios from 'axios';
import $ from 'jquery'; 



export default {
  // methods: {
  //   numberWithCommas(x) {
  //       return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  //   }
  // },
  filters: {
    rupiah: function(value) {
      // return value.toUpperCase();
      // Create our number formatter.
      var formatter = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });

      return formatter.format(value); /* $2,500.00 */
    }
  },
  computed : {
    years () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 2019}, (value, index) => new Date().getFullYear() - index)
    }
  },
  mounted(){
    console.log(process.env.VUE_APP_BASE_URL);
    this.loadData();
    let us = JSON.parse(this.$store.state.users);
      // alert(us.name);
      if(us.level=='1'){
        this.add= true;
      }
  },
  data: function() {
        return {
            deleteModal: false,
            products:[],
            items:[],
            idselected:0,
            add:false,
            itemlengths:0,
            tahun: new Date().getFullYear(),
        }
  },
  methods: {
    confirmDelete: function(id){
      // alert(id);
      this.idselected = id;
      this.deleteModal = true;
    },
    deleteIklan: function(){
      // alert(this.idselected);
      axios.post(process.env.VUE_APP_BASE_URL+"iklan/delete/"+this.idselected)
        .then((response) => {
            
            if(response.status==200){
              this.deleteModal = false;
              $(this.$refs.table).DataTable().draw();
            }
            else{
              this.myError = true;
            }            
        })
    },
    loadData: function() {
               
          axios
        .post(
          process.env.VUE_APP_BASE_URL+"api/public/index.php/template/?key=$2y$10$AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu",false
        )
        .then((response) => {
          this.items=response.data.data;
          this.itemlengths = this.items.length;

          // alert(JSON.stringify(this.items));
        });
      if (this.tahun == null){
      // your code here.
        // axios
        // .get("https://probowsolution.com/sippd/api/public/index.php/User/2021?key=704b037a97fa9b25522b7c014c300f8a")
        // .then((response)=>
        // {
          // this.products = response.data;
          $('#datatable').DataTable({
            "processing": true,
              "serverSide": true,
               "pageLength": 50,
              "ajax":{
                       "url": process.env.VUE_APP_BASE_URL+"iklan/datatable",
                       "dataType": "json",
                       "type": "POST"
                     },
              "columns": [
                  { "data": "no" },
                  { "data": "title" },
                  { "data": "advert_size" },
                  { "data": "val1" },
                  { "data": "link" },
                  { "data": "status" },
                  { "data": "edit", class:"text-center" },
              ]  

          });
          
          $.fn.dataTableExt.sErrMode = 'throw';
          
        // })
      }
      else{
        const table = $(this.$refs.table).DataTable({
          // $('#datatable').DataTable({
                      "processing": true,
              "serverSide": true,
               "pageLength": 50,
              "ajax":{
                       "url": process.env.VUE_APP_BASE_URL+"iklan/datatable",
                       "dataType": "json",
                       "type": "POST"
                     },
              "columns": [
                  { "data": "no" },
                  { "data": "title" },
                  { "data": "advert_size" },
                  { "data": "val1" },
                  { "data": "link" },
                  { "data": "status" },
                  { 
                    "data": null, 
                    class:"text-center",
                    "render": function(data, type, row) {
                            // alert(data.id_penomoran);
                            return row.edit;
                        }, 
                  },
              ]  
          
 
          });
          
          $.fn.dataTableExt.sErrMode = 'throw';
          
          const self = this
          $('tbody', this.$refs.table).on( 'click', '.btnhapus', function(){
              const cell = table.cell( $(this).closest("td") );
              const objdata =cell.data();
              // alert(objdata.id);
              self.confirmDelete(objdata.id)
          });
          
        // })
      }
      
    }
  }
}
</script>